import React from "react"

import Layout from "../components/layout"
import PolicyBlock from "../components/PolicyBlock/PolicyBlock.js"

const IndexPage = () => (
  <Layout>
    <PolicyBlock/>
  </Layout>
)

export default IndexPage

import React from "react"
import { Fragment } from "react";
import styles from './policyBlock.module.css';


const PolicyBlock = () => {
  return (
    <Fragment>
      <div className={styles.policyContainer}>
        <h1 className={styles.pageTitle}>
          Privacy Policy
        </h1>

        <div className={styles.textContainer}>
          <div className={styles.section}>
            <p className={styles.text}>
              This website is operated by NAHUAL VENTURES CORP., ("​<strong>Exeboard​</strong>", "​<strong>We​</strong>" or the "<strong>​Company​</strong>"). Exeboard is a
              web-based and mobile service, designed to enhance board and leadership performance through simplified management of
              company board meetings. We respect your personal information and therefore we have established a comprehensive privacy
              policy, which describes the types of information that we collect from you and our practices for collecting, using,
              maintaining, protecting and disclosing this information.
            </p>

            <p className={styles.text}>
              This privacy policy applies to the information that we collect through this website exeboard.com (the "​<strong>Website​</strong>"), through
              e-mail or other electronic messages between you and the Website and through our mobile application (the "​<strong>Application​</strong>").
              For avoidance of any doubt this privacy policy will not apply with regards to personal information provided by the users of
              this website during payment transactions executed through third parties. All personal information collected during such
              transactions shall be subject to the applicable privacy policy established by those third parties.
            </p>

            <p className={styles.text}>
            Please read this policy carefully to understand our policies and practices regarding your personal information and how we
            will treat it. If you do not agree with our policies and practices, your choice is not to use our Website or our Applications
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Children under age of 13
            </div>

            <p className={styles.text}>
              This Website is not intended for use by children under 13 years of age. No one under age 13 may provide any personal
              information to or on the Website. We do not knowingly collect personal information from children under 13. If you are
              under 13, do not use or provide any information on this Website, use any of the interactive or public comment features of
              this Website or provide any information about yourself to us, including your name, address, e-mail address or any screen
              name or user name you may use. If we learn we have collected or received personal information from a child under 13
              without verification of parental consent, we will delete that information. If you believe we might have any information
              received from a child under 13, please contact us at NAHUAL VENTURES CORP., at Via 4, 1-00, zona 4, Edificio
              Campus Tec 2, Of. 607, Guatemala City, Guatemala.
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Personal Information Defined
            </div>

            <p className={styles.text}>
              Personal information is any information that can be used to personally identify an individual user, such as name, physical
              address, e-mail address, company affiliation, role, telephone number, social security number and any other similar
              information that can be used to uniquely identify, contact or locate an individual.
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Information we collect about you
            </div>

            <p className={styles.text}>
              <div className={styles.sectionSubtitle}>
                Personal information from individuals.
              </div>

              <div>
                The personal information that we collect is:
              </div>
              <ul>
                <li>
                  required information: names; e-mail addresses, phone numbers, addresses and zip codes, [billing information];
                </li>
                <li>
                  optional, if provided by the individual end users: individual's company roles, any additional information users
                  may share with us;
                </li>
              </ul>
            </p>

            <p className={styles.text}>
              <div>
                <span className={styles.sectionSubtitle}>
                  Information that we collect from companies:&nbsp;
                </span>
                <span>
                  We collect the following information from companies using our board management system:
                </span>
              </div>
              <ul>
                <li>Business name;</li>
                <li>Business address;</li>
                <li>Zip code;</li>
                <li>Business phone and email</li>
                <li>Billing address</li>
                <li>Billing information</li>
              </ul>
            </p>

            <p className={styles.text}>
              <div>
                <span className={styles.sectionSubtitle}>
                  Other information:&nbsp;
                </span>
                <span>
                  Additionally we will be collecting also:
                </span>
              </div>
              <ul>
                <li>board meeting scheduling information;</li>
                <li>details of transactions you carry out through this Website or the Application;</li>
                <li>your search queries on this Website or the Application;</li>
                <li>any information that you post on our Website, if related to Exeboard, other than board meeting information;</li>
                <li>your log-in and password details.</li>
              </ul>
            </p>

            <p className={styles.text}>
              <div className={styles.sectionSubtitle}>
                Usage details, IP address.
              </div>
              <div>
                While you use/ navigate through our Website or Application, we may automatically collect certain information about your
                equipment, browsing actions and patterns, including:
              </div>
              <ul>
                <li>Details of your visits to our website, including traffic data, location data, logs and other</li>
                <li>communication data and the resources that you access and use on our website;</li>
                <li>Information about your computer and internet connection, including your IP address,</li>
                <li>operating system and browser type.</li>
              </ul>
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Cookies and other tracking technologies
            </div>

            <p className={styles.text}>
              The information that we collect automatically is statistical data and cannot be used to identify you as an individual. It helps
              us to improve our Website and Application and to deliver a better and more personalized service. The technologies we use
              for this automatic data collection may include cookies. A cookie is a small file placed on the hard drive of your computer.
              You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this
              setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it
              will refuse cookies, our system will issue cookies when you direct your browser to our Website.
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              How we use your information
            </div>

            <p className={styles.text}>
              <div>
                (1) <span className={styles.underlined}>We will use the personal information you provide​:</span>
              </div>
              <ul>
                <li>to create you profile and administer your account with us;</li>
                <li>to keep you informed about the board meeting organized through the Website and the Application;</li>
                <li>to segment permissions set by system admin and to monitor security and other potential threats based on distorted patterns of common usage and device usage;</li>
                <li>to provide you with information about our services and products;</li>
                <li>to notify you about changes to our Website and the Applications, including this privacy policy, or any products or services we offer or provide though it.</li>
              </ul>
            </p>

            <p className={styles.text}>
              <div>
                (2) <span className={styles.underlined}>We will use the non-personally identifiable information​:</span>
              </div>
              <ul>
                <li>to customize our services for you and to personalize and improve your experience on the Website and our Application.</li>
                <li>to understand how users use the Website and our Application and estimate their usage patterns.</li>
                <li>to collect information about your preferences about browser type and operating system;</li>
              </ul>
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Information Sharing
            </div>

            <p className={styles.text}>
              Unless otherwise provided herein, we do not share personal information and non-personally identifiable information
              collected via this Website or our Application with other companies or individuals. We will only share the personal
              information submitted by individual end users with the companies registered with the Website or the Applications with
              whom the individuals are affiliated.
            </p>

            <p className={styles.text}>
              We may share personal information with service providers, contractors and other companies to fulfill your orders, to
              provide our services, operate our business, communicate with you and make available our services, the Website and the
              Application. All such service providers and contractors will be bound to protect your personal information by confidently
              provisions.
            </p>

            <p className={styles.text}>
              [We may share certain non-personally identifiable information with advertisers and their marketing providers, so they may
              provide targeted content and advertising to you on websites and mobile applications. These may include demand-side and
              supply-side advertising platforms, data management platforms, advertising agency trading desks, proximity solution
              providers, and other advertising technology providers, so they may provide targeted content and advertising to end users and
              others on third-party websites, mobile applications, and other advertising mediums (email, direct mail, display media), and
              so that they may measure the effectiveness of this marketing.
            </p>

            <p className={styles.text}>
              We reserve the right to disclose your personal information to third parties if required by a court order, law or legal process,
              including responding to any government or regulatory request. We may also disclose your personal information if we
              believe disclosure is necessary or appropriate to protect the rights, property, or safety of Exeboard, our users or others.
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Security
            </div>

            <p className={styles.text}>
              We take reasonable measures and precautions designed to secure your personal information from accidental loss and from
              unauthorized access, use, alteration and disclosure. All information you provide to us is stored on our secure servers behind
              firewalls. We restrict access to personal information to employees, contractors and agents who need to know that
              information in order to operate, develop or improve our services. These individuals are bound by confidentiality obligations
              and may be subject to discipline, including termination, if they fail to meet these obligations. However, we are unable to
              guarantee that the security measures we take will not be penetrated or compromised or that your information will remain
              secure under all circumstances.
            </p>

            <p className={styles.text}>
              Areas of our Website and Applications that collect personal data use Secure Sockets Layer (SSL) encryption (DigiCert
              Certification); however to take advantage of this you must use an SSL-enabled browser. Doing so will protect the
              confidentiality of your personal data while it is transmitted over the Internet.
            </p>

            <p className={styles.text}>
              The safety and security of your information also depends on you. Where we have given you a password for access to certain
              parts of our Website and Applications, you are responsible for keeping this password confidential. We ask you not to share
              your password with anyone. We urge you to be careful about giving out information in public areas of the Website or
              Applications like message boards. The information you share in public areas may be viewed by any user of the Website or
              the Applications.
            </p>

            <p className={styles.text}>
              Although we do our best to protect your personal information, the transmission of information via the internet is not
              completely secure and we cannot guarantee the security of your personal information transmitted to our Website or
              Applications. Any transmission of personal information is at your own risk.
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Access
            </div>

            <p className={styles.text}>
              We will provide you with access to your personal information that we store to the extent it is available and allow you to
              correct, amend or delete inaccurate information.
            </p>

            <p className={styles.text}>
              You may also send us an e-mail at ​<a href="mailto:info@exeboard.com​">info@exeboard.com​</a> to request access to, correct or delete any personal information that
              you have provided to us. We cannot delete your personal information except by also deleting your user account. We may
              not accommodate a request to change information if we believe the change would violate any law or legal requirement or
              cause the information to be incorrect.
            </p>

            <p className={styles.text}>
              If you delete your content submission from this Website or the Applications, copies of your content submissions may
              remain viewable in cached and archived pages, or might have been copied or stored by other Website or the Applications'
              users. Proper access and use of information provided on the Website, including content submissions, is governed by our
              terms of use ​<a href="https://www.exeboard.com​">https://www.exeboard.com​</a>.
            </p>

            <p className={styles.text}>
              California Civil Code Section § 1798.83 permits the users of this Website that are California residents to request certain
              information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make
              such a request, please send an e-mail to ​<a href="mailto:info@exeboard.com​">info@exeboard.com​</a> or write us at: Exeboard, LLC, [8 Spruce Street, 29N, New
              York, New York].
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Changes to this Privacy Policy
            </div>

            <p className={styles.text}>
              Please note that this privacy policy may change from time to time. We will keep you informed of any amendments that we
              make to this privacy policy. We will post any changes that we make to our privacy policy on the Website home page with a
              notice that the privacy policy has been updated.
            </p>

            <p className={styles.text}>
              If we make material changes to how we treat our users' personal information, we will notify you by e-mail to the primary
              e-mail address specified in your account and/or through a notice on the website home page. It is your responsibility to
              provide us with an up-to-date e-mail address for you, and for periodically visiting our website and this privacy policy to
              check for any changes.
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Contact information
            </div>

            <p className={styles.text}>
              If you have any additional questions or concerns about this privacy policy, please feel free to contact us any time through
              this Website or by writing to us at the following address:
              Exeboard, LLC, [8 Spruce Street, 29N, New York, New York]
            </p>

            <p className={styles.text}>
              Last Updated: May [ ], 2018
            </p>
          </div>
          </div>
      </div>
    </Fragment>
  );
}

PolicyBlock.propTypes = {
}

PolicyBlock.defaultProps = {

}


export default PolicyBlock
